import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Peak Performance Gym
			</title>
			<meta name={"description"} content={"Emelje fel fitneszútját"} />
			<meta property={"og:title"} content={"Peak Performance Gym"} />
			<meta property={"og:description"} content={"Emelje fel fitneszútját"} />
			<meta property={"og:image"} content={"https://tralorsbacki.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tralorsbacki.com/img/5498150.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tralorsbacki.com/img/5498150.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tralorsbacki.com/img/5498150.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tralorsbacki.com/img/5498150.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://tralorsbacki.com/img/5498150.png"} />
			<meta name={"msapplication-TileImage"} content={"https://tralorsbacki.com/img/5498150.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Lépjen kapcsolatba velünk
			</Text>
			<Text as="p" font="--lead" margin="20px 0 180px 0" text-align="center">
				• Helyszín: Central Parkway – Budapest, Bécsi út 267, 1037 Magyarország
				<br />
				<br />
				• Telefon: +36 70 427 7227
				<br />
				<br />
				• E-mail: info@tralorsbacki.com
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});